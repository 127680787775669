.loading {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: var(--u);
    border: var(--u) solid var(--primary);
    border-radius: 50%;
    animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary) transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }
  }
}

.loadingSmall {
  @extend .loading;
  width: var(--u3);
  height: var(--u3);

  div {
    width: var(--u3);
    height: var(--u3);
    margin: 0;
  }
}

.white {
  div {
    border: var(--u) solid var(--white);
    border-color: var(--white) transparent transparent transparent;
  }
}

.primary {
  div {
    border: var(--u) solid var(--primary);
    border-color: var(--primary) transparent transparent transparent;
  }
}

.secondary {
  div {
    border: var(--u) solid var(--secondary);
    border-color: var(--secondary) transparent transparent transparent;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
