.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: var(--u);

  .header {
    display: flex;
    gap: var(--u3);
    margin-bottom: var(--u4);
    width: 100%;

    .titleContainer {
      display: flex;
      flex-direction: column;
      gap: var(--u);

      .title {
        width: 100%;
        font-size: var(--font-24);
        margin: 0;
      }

      .description {
        margin: 0;
        font-size: var(--font-16);
        color: var(--secondary-text-color);
      }
    }
  }

  .hashContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--u);

    .hashTitle {
      margin: 0;
      font-weight: 700;
      font-size: var(--font-16);
    }

    .hash {
      color: var(--secondary-text-color);
      margin: 0;
      width: 100%;
      overflow-wrap: break-word;
      line-height: 22px;

      .hashTitle {
        opacity: var(--defaultOpacity);
      }
    }
  }

  .buttonContainer {
    height: var(--u5);
    width: 100%;
    margin-top: var(--u2);
  }

  .link {
    width: 100%;
    color: var(--primary);
    margin: 0;
  }
}
