@import url('https://use.typekit.net/vah2ljw.css');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'articulat-cf';
  background-color: var(--background);
  color: var(--text-color);
  height: 100%;
  overflow-y: scroll;
}

#__next {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.web3modal-modal-lightbox {
  z-index: var(--layer-10) !important;
}

html::-webkit-scrollbar-track {
  background-color: #e1e1e1;
}

html::-webkit-scrollbar {
  width: var(--u);
  background-color: transparent;
}

html::-webkit-scrollbar-thumb {
  background-color: #8f8c9c;
  border-radius: 100px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

:root {
  /* GRID */
  --smallestUnit: 2px;
  --smallUnit: 4px;
  --u: 8px;
  --u2: 16px;
  --u3: 24px;
  --u4: 32px;
  --u5: 40px;
  --u6: 48px;
  --u7: 56px;
  --u8: 64px;
  --u9: 72px;
  --u10: 80px;

  /* WIDTH */
  --maxWidth: 1250px;

  /* FONT SIZES */
  --font-10: 0.625rem;
  --font-12: 0.75rem;
  --font-14: 0.875rem;
  --font-15: 0.938rem;
  --font-16: 1rem;
  --font-18: 1.125rem;
  --font-20: 1.25rem;
  --font-24: 1.5rem;
  --font-32: 2rem;
  --font-36: 2.25rem;
  --font-42: 2.625rem;
  --font-64: 4rem;
  --font-80: 5rem;

  /* COLORS */
  --white: #fff;

  --primary: #008cf4;
  --primary-50: #d3ecff;
  --primary-hover: #016bbd;
  --primary-100: #d4d4fa;
  --primary-200: #a6a6f4;

  --secondary-brand: #34c53a;
  --secondary: #34c53a;
  --secondary-hover: #26952a;

  /* z-index */
  --layer-0: -1;
  --layer-1: 1;
  --layer-2: 2;
  --layer-3: 3;
  --layer-4: 4;
  --layer-5: 5;
  --layer-6: 6;
  --layer-7: 7;
  --layer-10: 10;

  /* NAVBAR */
  --navbarHeight: 88px;

  /* Current Network or Wallet Disconected */
  --currentNetwork: 48px;

  /* OPACITY */
  --defaultOpacity: 50%;

  /* TOOLTIP*/
  --tooltip-background: #1f1939;

  /* FONT */
  --secondary-font: 'IBM Plex Mono', monospace;
}

body[data-theme='light'] {
  /* GRAYS */
  --gray-50: #fafafa;
  --gray-100: #e1e1e1;
  --gray-200: #cccccc;
  --gray-500: #9a9a9a;
  --gray-900: #1f1939;

  /* SHADOWS */
  --shadow-tables: 0px 0px 10px rgba(0, 0, 0, 0.15);
  --shadow-header-mob: 0px 2px 4px rgba(0, 0, 0, 0.15);
  --dropdown-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

  /* BACKGROUND */
  --background: #F9FAFB;

  /* LINES */
  --solid-line: 1px solid#e0e0e0;
  --dashed-line: 1px dashed #d9d9d9;

  /* TEXT */
  --text-color: #0e0e0e;
  --secondary-text-color: #9a9a9a;

  /* OVERLAY */
  --overlay-background: black;

  /* SKELETON */
  --skeleton-base: #ebebeb;
  --skeleton-highlight: #f5f5f5;

  /*STATUS */
  --error: #b00020;
  --success: #63ca10;
}

body[data-theme='dark'] {
  /* GRAYS */
  --gray-50: #1d1c1c;
  --gray-100: #8f8c9c;
  --gray-200: #1d1c1c;
  --gray-500: #e1e1e1;
  --gray-900: #fafafa;

  /* SHADOWS */
  --shadow-tables: 0px 0px 5px rgba(143, 140, 156, 0.1);
  --shadow-header-mob: 0px 2px 4px rgba(143, 140, 156, 0.1);
  --dropdown-shadow: 0px 2px 2px rgba(143, 140, 156, 0.1);

  /* BACKGROUND */
  --background: #121212;

  /* LINES */
  --solid-line: 1px solid#e0e0e0;
  --dashed-line: 1px dashed #d9d9d9;

  /* TEXT */
  --text-color: #fafafad4;
  --secondary-text-color: #e1e1e1;

  /* OVERLAY */
  --overlay-background: #121212;

  /* SKELETON */
  --skeleton-base: #202020;
  --skeleton-highlight: #444;

  /*STATUS */
  --error: #b00020;
  --success: #63ca10;
}

.style_loading__4lNOm,.style_loadingSmall__bbjtI{display:inline-block;position:relative;width:80px;height:80px;margin:0 auto}.style_loading__4lNOm div,.style_loadingSmall__bbjtI div{box-sizing:border-box;display:block;position:absolute;width:64px;height:64px;margin:var(--u);border:var(--u) solid var(--primary);border-radius:50%;animation:style_loading__4lNOm 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;border-color:var(--primary) rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0)}.style_loading__4lNOm div:nth-child(1),.style_loadingSmall__bbjtI div:nth-child(1){animation-delay:-0.45s}.style_loading__4lNOm div:nth-child(3),.style_loadingSmall__bbjtI div:nth-child(3){animation-delay:-0.15s}.style_loading__4lNOm div:nth-child(2),.style_loadingSmall__bbjtI div:nth-child(2){animation-delay:-0.3s}.style_loadingSmall__bbjtI{width:var(--u3);height:var(--u3)}.style_loadingSmall__bbjtI div{width:var(--u3);height:var(--u3);margin:0}.style_white__9aplR div{border:var(--u) solid var(--white);border-color:var(--white) rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0)}.style_primary__kiUiP div{border:var(--u) solid var(--primary);border-color:var(--primary) rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0)}.style_secondary__0pW5u div{border:var(--u) solid var(--secondary);border-color:var(--secondary) rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0)}@keyframes style_loading__4lNOm{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}
.style_container__IjS1h{display:flex;flex-direction:column;align-items:center;position:relative;gap:var(--u)}.style_container__IjS1h .style_header__ctCwe{display:flex;gap:var(--u3);margin-bottom:var(--u4);width:100%}.style_container__IjS1h .style_header__ctCwe .style_titleContainer__ulLFE{display:flex;flex-direction:column;gap:var(--u)}.style_container__IjS1h .style_header__ctCwe .style_titleContainer__ulLFE .style_title__7_Ky1{width:100%;font-size:var(--font-24);margin:0}.style_container__IjS1h .style_header__ctCwe .style_titleContainer__ulLFE .style_description__jmJ9k{margin:0;font-size:var(--font-16);color:var(--secondary-text-color)}.style_container__IjS1h .style_hashContainer__S5NOj{width:100%;display:flex;flex-direction:column;gap:var(--u)}.style_container__IjS1h .style_hashContainer__S5NOj .style_hashTitle__JHqo9{margin:0;font-weight:700;font-size:var(--font-16)}.style_container__IjS1h .style_hashContainer__S5NOj .style_hash__RnwyY{color:var(--secondary-text-color);margin:0;width:100%;overflow-wrap:break-word;line-height:22px}.style_container__IjS1h .style_hashContainer__S5NOj .style_hash__RnwyY .style_hashTitle__JHqo9{opacity:var(--defaultOpacity)}.style_container__IjS1h .style_buttonContainer__oxsrL{height:var(--u5);width:100%;margin-top:var(--u2)}.style_container__IjS1h .style_link__5gqrF{width:100%;color:var(--primary);margin:0}
